
	import { Component, Mixins } from 'vue-property-decorator';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { CartItemType, NAMESPACE as USER_NAMESPACE, PaymentChannel, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconCircleNotch from '@pixcap/ui-library/components/Icons/IconCircleNotch.vue';
	import IconCreditCard from '@pixcap/ui-library/components/Icons/IconCreditCard.vue';
	import IconPaypal from '@pixcap/ui-library/components/PaymentIcons/IconPaypal.vue';
	import IconMasterCard from '@pixcap/ui-library/components/PaymentIcons/IconMasterCard.vue';
	import IconUnionPay from '@pixcap/ui-library/components/PaymentIcons/IconUnionPay.vue';
	import IconVisa from '@pixcap/ui-library/components/PaymentIcons/IconVisa.vue';
	import { mapState } from 'vuex';
	import { STRIPE_UNSUPPORTED_COUNTRIES } from '@pixcap/ui-core/constants/subscription.constants';

	@Component({
		name: 'CartPaymentMethodModal',
		components: {
			BaseModal,
			IconPaypal,
			IconCircleNotch,
			IconCreditCard,
			IconMasterCard,
			IconUnionPay,
			IconVisa,
			BodyText,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				cartItemsId: (state: UserState) => state.cartItemsId,
				showCartPaymentMethodModal: (state: UserState) => state.showCartPaymentMethodModal,
			}),
		},
	})
	export default class CartPaymentMethodModal extends Mixins(UpgradeSubscriptionMixin) {
		cartItemsId: UserState['cartItemsId'];
		showCartPaymentMethodModal: UserState['showCartPaymentMethodModal'];

		get isBuyNowOption() {
			return !!this.showCartPaymentMethodModal.items?.length;
		}

		async payWithCreditCard() {
			const itemIds = this.isBuyNowOption
				? this.showCartPaymentMethodModal.items.filter((item) => item.itemType === CartItemType.ITEM).map((item) => item.itemId)
				: this.cartItemsId.filter((item) => item.itemType === CartItemType.ITEM).map((item) => item.itemId);
			const packIds = this.isBuyNowOption
				? this.showCartPaymentMethodModal.items.filter((item) => item.itemType === CartItemType.PACK).map((item) => item.itemId)
				: this.cartItemsId.filter((item) => item.itemType === CartItemType.PACK).map((item) => item.itemId);

			if (this.isBuyNowOption) {
				await this.openCartCheckoutPaymentPortal({
					itemIds: itemIds || [],
					packIds: packIds || [],
					channel: this.payWithCreditCardChannel,
				});
				return;
			}

			await this.openCartCheckoutPaymentPortal({ itemIds, packIds, channel: this.payWithCreditCardChannel });
		}

		async payWithPaypal() {
			const itemIds = this.isBuyNowOption
				? this.showCartPaymentMethodModal.items.filter((item) => item.itemType === CartItemType.ITEM).map((item) => item.itemId)
				: this.cartItemsId.filter((item) => item.itemType === CartItemType.ITEM).map((item) => item.itemId);
			const packIds = this.isBuyNowOption
				? this.showCartPaymentMethodModal.items.filter((item) => item.itemType === CartItemType.PACK).map((item) => item.itemId)
				: this.cartItemsId.filter((item) => item.itemType === CartItemType.PACK).map((item) => item.itemId);

			if (this.isBuyNowOption) {
				await this.openCartCheckoutPaymentPortal({
					itemIds: itemIds || [],
					packIds: packIds || [],
					channel: PaymentChannel.PAYPAL,
				});
				return;
			}

			await this.openCartCheckoutPaymentPortal({ itemIds, packIds, channel: PaymentChannel.PAYPAL });
		}

		handleClose() {
			UserMutations.setShowCartPaymentMethodModal(this.$store, null);
		}

		created() {
			this.handleCloseMethod = this.handleClose.bind(this);
		}
	}
