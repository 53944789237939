
	import { Vue } from 'vue-property-decorator';
	import { Component } from 'vue-property-decorator';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconGift from '@pixcap/ui-library/components/Icons/IconGift.vue';
	import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import { mapState } from 'vuex';
	import { NAMESPACE as USER_NAMESPACE, UserState } from '@pixcap/ui-core/models/store/user.interface';

	@Component({
		name: 'CartPaymentStatusPopoverModal',
		components: { IconClose, BodyText, HeadingText, BaseModal, Button, CaptionText, IconGift },
		computed: {
			...mapState(USER_NAMESPACE, {
				showCartPaymentStatusPopoverModal: (state: UserState) => state.showCartPaymentStatusPopoverModal,
			}),
		},
	})
	export default class CartPaymentStatusPopoverModal extends Vue {
		showCartPaymentStatusPopoverModal: UserState['showCartPaymentStatusPopoverModal'];

		items: any = [];
		packs: any = [];

		get itemsId() {
			return this.showCartPaymentStatusPopoverModal.itemsId;
		}

		get packsId() {
			return this.showCartPaymentStatusPopoverModal.packsId;
		}

		get singleThumbnail() {
			if (this.isPurchasePackOnly) {
				return this.packs[0].thumbnailUrl;
			}
			return this.items[0].thumbnailUrl.url;
		}

		get isOver4Items() {
			return this.thumbnails.length > 4;
		}

		get thumbnails() {
			const itemsThumbnail = this.items.map((item) => item.thumbnailUrl.url);
			const packsThumbnail = this.packs.map((item) => item.thumbnailUrl);
			return itemsThumbnail.concat(packsThumbnail);
		}

		get isPurchasePackOnly() {
			return this.items.length === 0 && this.packs.length > 0;
		}

		get isPurchaseOneItemOnly() {
			if (this.items.length === 1 && this.packs.length === 0) return true;
			return this.items.length === 0 && this.packs.length === 1;
		}

		async fetchItemsDetails() {
			const response = await UserActions.fetchCartItems(this.$store, { manuallyFetch: true, itemIds: this.itemsId, packIds: this.packsId });
			if (response) {
				this.items = response.filter((item) => item.itemId);
				this.packs = response.filter((item) => item.packId);
			}
		}

		handleOpenMyPurchases() {
			this.closeModal();
			this.$router.push({ name: 'AssetsPage', query: { tab: 'purchases' } });
		}

		closeModal() {
			UserMutations.setShowCartPaymentStatusPopoverModal(this.$store, null);
		}

		async mounted() {
			await this.fetchItemsDetails();
		}
	}
